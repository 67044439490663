import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { IconReplacePipe } from "src/pipes/icon-replace.pipe";
import { TooltipComponent } from "../../tooltip.component";

@Component({
  selector: "app-text-tooltip",
  imports: [TranslateModule, IconReplacePipe],
  templateUrl: "./text-tooltip.component.html",
  styleUrl: "./text-tooltip.component.less",
})
export class TextTooltipComponent extends TooltipComponent<string> {
  public constructor() {
    super();
  }
}
